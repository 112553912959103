<template>
  <div>
    <div class="bg" />
    <div class="container">
      <div class="bar">
        <div class="title">入住信息查询</div>   
        <div class="rb1" @click="goback">
          <img src="@/assets/fh.png" class="icon" />
          <div class="text">返回</div>
        </div>  
      </div>
      <div class="content">
        <div class="items">         
          <!--<div class="item">
            <div class="box qq">
              <div class="name">
                <div class="headline h1">{{ startdate }}&ensp;~&ensp;{{ enddate }}</div>
                <div v-if="gdflag">
                  <div class="headline h2 d3 xz cur" @click="gd()">更多</div>
                </div>
                <div v-if="gdflag === false">
                  <div class="headline h2 d3 cur" @click="gd()">更多</div>
                </div>              
                <div v-if="byflag">
                  <div class="headline h2 d3 xz cur" @click="by()">本月</div>
                </div>
                <div v-if="byflag === false">
                  <div class="headline h2 d3 cur" @click="by()">本月</div>
                </div>                                
                <div v-if="bzflag">
                  <div class="headline h2 d3 xz cur" @click="bz()">本周</div>
                </div>
                <div v-if="bzflag === false">
                  <div class="headline h2 d3 cur" @click="bz()">本周</div> 
                </div> 
                <div v-if="jrflag">
                  <div class="headline h2 d3 xz cur" @click="jr()">今日</div>
                </div>
                <div v-if="jrflag === false">
                  <div class="headline h2 d3 cur" @click="jr()">今日</div> 
                </div>                                          
              </div>          
            </div>           
          </div>
          <div v-show="gdflag">
          <div class="item">
            <div class="box qq">
              <div class="name"> 
                <div class="headline h1 d5">选择日期：</div>               
                <div class="headline h1 d6">                  
                  <input type="date" class="datespace d" v-model="startdate"/>
                </div> 
                <div class="headline h1 d5">~</div>               
                <div class="headline h1 d5 d8">                  
                  <input type="date" class="datespace d" v-model="enddate"/>
                </div>                
              </div>          
            </div>              
          </div>
          </div>-->          
          <div class="item">
            <div class="box zz">
              <div class="name"> 
                <div class="headline h1 d5 mid">
                  <select class="form_select" v-model="selectItem" @change="selectZt($event)">
                    <option v-for="ztitem in ztitems" :key="ztitem.code" :value="ztitem.code">{{ztitem.val}}</option>
                  </select>
                </div>               
                <div class="headline h1 d5">                  
                  <input type="text" v-model="site_name" class="input qy" placeholder="输入查询信息" />
                </div>                
              </div>         
            </div>  
            <div class="box yy" @click="query">
              <div class="name"> 
                <div class="headline h2 d6">                  
                  <div class="rb2 co">
                    <img src="@/assets/cx.png" class="icon" />
                    <div class="text">查询</div>
                  </div>
                </div>                
              </div>          
            </div>         
          </div>         
          <div class="empty" v-if="items.length === 0">
            <div>
              <img src="@/assets/empty.png" />
            </div>
            <div class="text">当前无信息</div>
          </div>
          <div class="item" v-if="items.length !== 0">
            <div class="box qq">
              <div class="name">
                <div class="headline h1 cur" @click="roomsort()">
                  房间
                </div> 
                <div class="headline h1 d21 dpx cur" @click="roomsort()">
                  <img src="@/assets/mrpx.png" v-if="roomstate === '2'" class="pxicon" />
                  <img src="@/assets/zpx.png" v-if="roomstate === '0'" class="pxicon" />
                  <img src="@/assets/dpx.png" v-if="roomstate === '1'" class="pxicon" />                  
                </div>       
                <div class="headline h1 d22 cur">姓名</div>
                <div class="headline h1 d23 cur">床位</div>                
                <div class="headline h1 cur" @click="datesort()">
                  日期
                </div> 
                <div class="headline h1 dpx cur" @click="datesort()">
                  <img src="@/assets/mrpx.png" v-if="datestate === '2'" class="pxicon" />
                  <img src="@/assets/zpx.png" v-if="datestate === '0'" class="pxicon" />
                  <img src="@/assets/dpx.png" v-if="datestate === '1'" class="pxicon" /> 
                </div>              
                <div class="headline h2 cur">状态</div>                                                   
              </div>          
            </div>           
          </div>
          <div class="item" v-for="item in items" :key="item.id">
            <div class="box y1" @click="details(item)">
              <div class="name">
                <span class="roomcode f">{{ item.room_name }}</span>               
              </div>
            </div>
            <div class="box y2" @click="details(item)">
              <div class="name">                              
                <span class="floor l">{{ item.user_name1 }}</span>
              </div>
              <div class="name">                              
                <span class="floor l">{{ item.user_name2 }}</span>
              </div>             
            </div>
            <div class="box y3" @click="details(item)">
              <div class="name">                              
                <span class="region">{{ item.nownum }}/{{ item.num_bed }}</span>
              </div>              
            </div>
            <div class="box y4" @click="details(item)">
              <div class="name">                              
                <span class="floor r">{{ item.checkin_date }}</span>
              </div>
              <div class="name">                              
                <span class="floor r">{{ item.checkout_date }}</span>
              </div>
              
            </div>
            <div class="box y5" @click="details(item)">
              <div class="name">                              
                <span v-if="item.checkin_type==='1'" class="state">入住</span>
                <span v-if="item.checkin_type==='0'" class="state zt">空闲</span>              
              </div>
              <div class="name">                          
                <span class="state xq" @click="details(item)">详情</span>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, map } from 'lodash-es';
import { Dialog, Toast } from 'vant';
import qs from 'qs';
import moment from "moment";

export default {
  created() {
    this.type = this.$route.query.type;
    this.unionid = this.$route.query.unionid;
    this.srole = this.$route.query.srole;
    
    this.startdate = moment().add(-6, 'day').format('YYYY-MM-DD');
    this.enddate = this.getdate();
    this.nowdate = this.getdate();
    //alert(this.startdate+'~'+this.enddate);

    this.queryLx();
    this.query();
  },

  mounted() {
    this.initCalendar()
  },
  data() {
    return {
      type: '',
      unionid: '',
      srole: true,
      jrflag: true,
      byflag: false,   
      bzflag: false, 
      gdflag: false, 
      sgyflag: false,
      lgyflag: false,
      zdyflag: false,
      selectItem: '1',
      floor: '',
      startdate: '',
      enddate: '',
      nowdate: '',
      dateSpace: '',
      time: new Date(),
      mydate: '',
      days: '0',
      site_name: '',
      roomflag: false,
      roomstate: '2',
      dateflag: false,
      datestate: '2',
      ztitems: [
        /*{code:'1', val: '搜房'},
        {code:'2', val: '搜人'}*/
      ],
      items: [
        /*{dxflag: false, room_name: '101', nownum: '3', num_bed: '3', checkin_date: '2020-01-02', checkout_date: '2020-01-05', user_name1: '王先生', user_name2: '张女士', checkin_type: '1', has_heater: '1', has_ac: '1', has_window: '1', has_toilet: '1', room_type: '00000001'},
        {dxflag: false, room_name: '102', nownum: '1', num_bed: '3', checkin_date: '2020-01-02', checkout_date: '2020-01-05', user_name1: '罗女士', user_name2: '东方不败', checkin_type: '0', has_heater: '1', has_ac: '1', has_window: '0', has_toilet: '1', room_type: '00000003'},
        {dxflag: false, room_name: '105', nownum: '2', num_bed: '3', checkin_date: '2020-01-02', checkout_date: '2020-01-05', user_name1: '李先生', user_name2: '孙先生', checkin_type: '0', has_heater: '1', has_ac: '1', has_window: '1', has_toilet: '1', room_type: '00000006'}*/
      ]
    };
  },
  methods: {
    goback() {
      this.$router.replace({path:'/admin/admin_index', query:{type:this.type,unionid:this.unionid,srole:this.srole}});
    },
    selectZt(e) {
      //Toast(e.target.value);
      // console.log(e.target.selectedIndex) // 选择项的index索引
      // console.log(e.target.value) // 选择项的value
      //this.queryroom();
    },
    jr() {
      this.jrflag = true;
      this.byflag = false;
      this.bzflag = false;
      this.gdflag = false;
      this.sgyflag = false;
      this.lgyflag = false;
      this.zdyflag = false;

      this.startdate = this.getdate();
      this.enddate = this.getdate();

      this.query();
    },
    by() {
      this.jrflag = false;
      this.byflag = true;
      this.bzflag = false;
      this.gdflag = false;
      this.sgyflag = false;
      this.lgyflag = false;
      this.zdyflag = false;

      this.startdate = moment(moment().month(moment().month()).startOf('month').valueOf()).format('YYYY-MM-DD');
      this.enddate = moment(moment().month(moment().month()).endOf('month').valueOf()).format('YYYY-MM-DD');

      this.query();
    },
    bz() {
      this.jrflag = false;
      this.byflag = false;
      this.bzflag = true;
      this.gdflag = false;
      this.sgyflag = false;
      this.lgyflag = false;
      this.zdyflag = false;

      this.startdate = moment(moment().week(moment().week()).startOf('week').add(1, 'days').valueOf()).format('YYYY-MM-DD')
      this.enddate = moment(moment().week(moment().week()).endOf('week').add(1, 'days').valueOf()).format('YYYY-MM-DD');

      this.query();
    },
    gd() {
      this.jrflag = false;
      this.byflag = false;
      this.bzflag = false;
      this.gdflag = true;
      this.sgyflag = false;
      this.lgyflag = false;
      this.zdyflag = false;
    },
    sgy() {
      this.jrflag = false;
      this.byflag = false;
      this.bzflag = false;
      this.gdflag = true;
      this.sgyflag = true;
      this.lgyflag = false;
      this.zdyflag = false;

      this.startdate = this.nowdate;
      this.enddate = this.computeYmd(this.nowdate, 3);

      this.query();
    },
    lgy() {
      this.jrflag = false;
      this.byflag = false;
      this.bzflag = false;
      this.gdflag = true;
      this.sgyflag = false;
      this.lgyflag = true;
      this.zdyflag = false;

      this.startdate = this.nowdate;
      this.enddate = this.computeYmd(this.nowdate, 6);

      this.query();
    },
    zdy() {
      this.jrflag = false;
      this.byflag = false;
      this.bzflag = false;
      this.gdflag = true;
      this.sgyflag = false;
      this.lgyflag = false;
      this.zdyflag = true;
    },
    getdate() {
      var date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();

      if (month >= 1 && month <= 9) {
          month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
          strDate = "0" + strDate;
      }
      var currentdate = year + "-" + month + "-" + strDate;
      return currentdate;
    },   
    computeYmd(val, n) {// 获取给定日期的 n个月后的日期  给定日期格式如：val = 2019-02-26; n = 多少个月
      let str = val.split('-');
      let d = new Date(str[0], str[1], str[2]);
      // 因为getMonth()获取的月份的值只能在0~11之间所以我们在进行setMonth()之前先给其减一
      d.setMonth((d.getMonth()-1) + n);
      let yy1 = d.getFullYear();
      let mm1 = d.getMonth()+1;
      let dd1 = d.getDate()-1;
      if(dd1 == '00'){
        mm1 = parseInt(mm1)-1;
        let new_date = new Date(yy1,mm1,1);
        dd1 = (new Date(new_date.getTime()-1000*60*60*24)).getDate()
      }
      if (mm1 < 10 ) {
        mm1 = '0' + mm1;
      }
      if (dd1 < 10) {
        dd1 = '0' + dd1;
      }
      return yy1 + '-' + mm1 + '-' + dd1;
    },
    initCalendar() {
      this.$calendarSwitch({
          element: this.$refs.calendarTigger,
          monthSize: 36,
          minDate: this.nowdate,
          maxDate: '2020-05-21',
          onSuccess: (data) => {
            this.dateSpace = data.start_date +'~'+ data.end_date;
            this.startdate = data.start_date;
            this.enddate = data.end_date;
            console.log(this.dateSpace);
          },
          onError: function(msg) {
            console.log(msg)
          }
      });
    },
    async queryLx() {
      let { data } = await this.axios.get('/ctid/TldjService/dj_selectdic');
      //this.ztitems = data.data;
      var test = data.data.replace(/-/g, "+").replace(/_/g, "/");
      this.ztitems = JSON.parse(decodeURIComponent(escape(window.atob(test.split('.')[1])))).data;

      this.selectItem = this.ztitems[0].code;
    },
    async query() {
      let { data } = await this.axios.post(
        '/ctid/TldjService/dj_selectroomlog',
        qs.stringify({
          roomnameorder: this.roomstate,
          timeorder: this.datestate,
          stime: this.startdate,
          etime: this.enddate,
          selectcode: this.selectItem,
          selectname: this.site_name
        })
      );
      if (data.flag === '0') {
        //this.items = data.data;  
        var test = data.data.replace(/-/g, "+").replace(/_/g, "/");
        this.items = JSON.parse(decodeURIComponent(escape(window.atob(test.split('.')[1])))).data; 
      }
    },
    async roomsort() {
      this.datestate ='2';
      if(this.roomstate==='0'){
        this.roomstate ='1';
        //alert('房间降序');
      }else{
        this.roomstate ='0';
        //alert('房间升序');
      }
      this.query();
      
    },
    async datesort() {
      this.roomstate ='2';
      if(this.datestate==='0'){
        this.datestate ='1';
        //alert('日期降序');
      }else{
        this.datestate ='0';
        //alert('日期升序');
      }
      this.query();
    },
    details(item) {
      this.$router.replace({path:'/barcounter/check_in_query_details', query:{
        type:this.type,
        unionid:this.unionid,
        srole:this.srole,
        room_code:item.room_code,
        session_id:item.session_id
      }});  
    }
    
  }
};
</script>

<style lang="less" scoped>
.bg {
  height: 366px;
}
.bar {
  margin: 48px 40px;
  overflow: hidden;
  .title {
    line-height: 50px;
    color: #fff;
    font-size: 33px;
    float: left;
    margin-top: 10px;
  }
  
  
  .rb {
    height: 64px;
    width: 146px;
    float: right;
    overflow: hidden;
    background: #a46fe6;
    border-radius: 38px;
    margin-right: 8px;
    .icon {
      width: 39px;
      height: 39px;
      margin: 13px;
      display: block;
      float: left;
    }
    
    .text {
      float: left;
      color: #fff;
      font-size: 30px;
      line-height: 64px;
    }
  }
  .rb1 {
    height: 64px;
    width: 146px;
    float: right;
    overflow: hidden;
    background: #a46fe6;
    border-radius: 38px;
    .icon {
      width: 39px;
      height: 39px;
      margin: 13px;
      display: block;
      float: left;
    }
    
    .text {
      float: left;
      color: #fff;
      font-size: 30px;
      line-height: 64px;
    }
  }
  
}

.rb2 {
    height: 80px;
    width: 80px;
    overflow: hidden;
    float: right;
    .icon1 {
      width: 50px;
      height: 50px;
      margin: 8px;
      display: block;

    }
  }
.content {
  padding: 0px;
  margin-top: 40px;
}

.xg {
  width: 660px;
  overflow: hidden;
  margin: 0px auto;
  padding: 30px 0px 20px 0px;
}

.items {
  overflow: hidden;
  padding-bottom: 32px;
  min-height: 616px;
  .item {
    margin: 0px 26px;
    padding: 24px 14px 22px;
    overflow: hidden;
    display: flex;
    border-bottom: 2px solid #eee;
    &.bor {
      border-bottom: 0px solid #eee;
    }
  }
  .nbox {
    flex: 1;
    overflow: hidden;
    .headline {
      font-size: 26px;
      color: rgb(136, 134, 134);   
      &.h1 {
        margin-right: 30px;
        float: left;
      }
      &.h2 {
        float: right;         
      }
      &.cc {
        color: rgb(255, 255, 255);   
      }
      &.d1 {
        margin-right: 100px;
        margin-left: 30px; 
      }
      &.d2 {
        margin-right: 30px;
      }
      &.d3 {
        margin-left: 10px; 
      }
      &.d5 {
        margin-right: 10px; 
      }
      &.d6 {
        margin-right: 0px; 
      }
      
    }
  }
  .box {
    flex: 1;
    overflow: hidden;
    flex:0 0 auto;
    &.y1 {
      width: 25%;
    }
    &.y2 {
      width: 23%;
    }
    &.y3 {
      width: 10%;
    }
    &.y4 {
      width: 30%;
    }
    &.y5 {
      width: 12%;     
    }  
    &.zz {
      width: 80%;
    }
    &.yy {
      width: 20%;
    }
    &.yy2 {
      width: 10%;     
    }
    &.yy1 {
      width: 34%;
    }
    &.qq {
      width: 100%;
    }
    .name {
      line-height: 50px;
      overflow: hidden;
      font-size: 32px;
      &.tt {
        margin-top: 32px;
      }
      &.jz {
        margin-top: 30px;
      }
      .arrow {
        float: right;
        display: block;
        margin-left: 10px;
        //margin-top: 30px;
        height: 40px;
      }
      .headline {
        font-size: 26px;       
        color: rgb(80, 80, 80);     
        &.h1 {
          margin-right: 10px;
          float: left;
        }
        &.h2 {
          float: right;         
        }
        &.cc {
          color: rgb(255, 255, 255);   
        }
        &.d1 {
          margin-right: 100px;
          margin-left: 30px; 
        }
        &.d21 {
          margin-right: 81px;
        }
        &.d22 {
          margin-right: 78px;
        }
        &.d23 {
          margin-right: 75px;
        }
        &.dpx {
          margin-top: 10px;
        }
        &.d2 {
          margin-right: 30px;
        }
        &.d3 {
          margin-left: 10px; 
        }
        &.d5 {
          margin-right: 15px; 
        }
        &.d6 {
          margin-right: 0px; 
        }
        &.d8 {
          margin-left: 18px; 
        }
        &.xz {
          color: rgb(14, 177, 206);   
        }
        &.cur {
          cursor: pointer;
        }
        &.mid {
          margin-top: 11px; 
          vertical-align: middle;
        }
        .pxicon {
          width: 26px;
          height: 26px;
          display: block;
          margin: 0px auto;          
        }
        .datespace {
          width: 330px; 
          height: 50px; 
          /* 清除默认边框 */
          border: 0;
          /* 清除默认的箭头样式 */
          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none;
          background-color: transparent;
          cursor: pointer;  
          &.bt {
            width: 110px; 
          }  
          &.d {
            width: 185px; 
          }         
        }
        .rb2 {
          height: 60px;
          width: 126px;
          //float: left;
          overflow: hidden;
          background: rgb(130, 189, 217);
          margin-top: 16px;
          vertical-align: middle;
          margin-left: 10px;
          border-radius: 15px;
          &.co {
            margin-top: 0px;
            background: rgb(178, 158, 214);
          }
          .icon {
            width: 30px;
            height: 30px;
            margin: 14px 5px 14px 13px;
            display: block;
            float: left;
          }        
          .text {
            float: left;
            color: #fff;
            font-size: 30px;
            line-height: 60px;
          }
        }
      }
      .imgpic {
        display: block;
        width: 114px;   
        height: 160px;  
        float: right;
      }
      .dxicon {
        width: 35px;
        height: 35px;
        margin-right: 10px;
        vertical-align: middle;
        margin-top: -6px;
      }
      .state {
        display: block;
        font-size: 26px;
        float: right;
        color: rgb(236, 120, 25);
        &.zt {
          color: rgb(4, 141, 61);
        }
        &.xq {
          color: rgb(33, 18, 243);
        } 
        &.j {
          margin-top: 30px;
        } 
      }
      .floor {
        display: block;
        float: right;
        font-size: 26px;
        color: rgb(168, 169, 170);
        margin-right: 20px;
        &.l {
          float: left;
        }
        &.r {
          margin-right: 5px;
        }
      }
      .roomcode {
        margin-right: 80px;
        &.f {
          font-size: 26px;
          color: rgb(168, 169, 170);
        }
      }
      .region {
        font-size: 26px;
        color: rgb(168, 169, 170);
        &.jz {
          margin-left: 20px;
        }
      }
      .cx {
        height: 76px;
        vertical-align: middle;
      }
      .icon {
        width: 48px;
        height: 48px;
        vertical-align: middle;
      }
      .input {
        flex: 1;
        margin-left: 15px;
        margin-top: 10px;
        vertical-align: middle;
        line-height: 50px;
        float: left;
        width: 260px;
        border: 0;
        color: #333;
        text-align: right;
        &.qy {
          font-size: 26px;
          color: rgb(184, 188, 190);
        }
      }
      ::-webkit-input-placeholder { /* WebKit browsers */
        color:rgb(184, 188, 190);
      }
      :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color:rgb(184, 188, 190);
      }
      ::-moz-placeholder { /* Mozilla Firefox 19+ */
        color:rgb(184, 188, 190);
      }
      :-ms-input-placeholder { /* Internet Explorer 10+ */
        color:rgb(184, 188, 190);
      }
      
      .form_select {
        /* 清除默认边框 */
        border: 0;
        /* 清除默认的箭头样式 */
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        /* 右侧添加小箭头的背景图 */
        background: url('/img/xl1.png') 130px center no-repeat;
        background-size: 26px;
        width: 160px;
        height: 50px;
        background-color: transparent;
        //float: right;
        font-size: 26px;
        color:  rgb(184, 188, 190);
        line-height: 50px;
        //margin-top: 10px;
        vertical-align: middle;
        &.ri {
          margin-right: 30px;
        }
      }
      select:focus { outline: none; }
      
    }
    .name1 {
      line-height: 50px;
      overflow: hidden;
      font-size: 26px;
      .state {
        display: block;
        float: right;
        color: rgb(236, 120, 25);
        &.zt {
          color: rgb(4, 141, 61);
        }
      }
      .floor {
        display: block;
        float: right;
        color: rgb(184, 188, 190);
        margin-right: 20px;
      }
    }
    .unames {
      line-height: 40px;
      font-size: 28px;
      margin-top: 8px;
      color: #918e8e;
      .pz {
        width: 46px;
        height: 46px;
      }
    }
    .del {
      width: 46px;
      height: 46px;
      display: block;
      float: right;
      //margin-top: 18px;
    }
    .edit {
      width: 46px;
      height: 46px;
      display: block;
      float: right;
      margin-right: 20px;
    }
    .view {
      margin-top: 18px;
      width: 198px;
      height: 64px;
      border: 2px solid rgba(0, 29, 255, 1);
      border-radius: 44px;
      text-align: center;
      line-height: 64px;
      color: rgba(0, 29, 255, 1);
      font-size: 28px;
      float: right;
      margin-right: 30px;
    }
  }
}
.empty {
  text-align: center;
  margin-top: 212px;
  img {
    width: 150px;
  }
  .text {
    line-height: 40px;
    font-size: 28px;
    margin-top: 20px;
  }
}
.tj {
  width: 100%;
  height: 310px;
  text-align: center;
  overflow: hidden;
  font-size: 32px;
  display: flex;
  line-height: 50px;
  color: #000000;
  background: rgba(238, 238, 238, 1);
  border-radius: 8px;  
  cursor: pointer;
  &.primary {
    //background: linear-gradient(90deg, rgba(98, 226, 230, 1) 0%, rgba(18, 129, 132, 1) 100%);  
    color: rgb(0, 0, 0);
    background: rgb(246, 241, 250);
    margin-bottom: 20px;
  }
  
  &.primary0 {
    //background: linear-gradient(90deg, rgba(255, 229, 59, 1) 0%, rgba(255, 37, 37, 1) 100%);
    background: rgb(240, 118, 48);
    color: #fff;
    float: right;
  }
  
  &.primary1 {
    //background: linear-gradient(90deg, rgba(255, 229, 59, 1) 0%, rgba(255, 37, 37, 1) 100%);   
    background: rgb(114, 191, 214);
    color: #fff;
    float: right;
  }
  &.primary2 {
    //background: linear-gradient(90deg, rgba(255, 229, 59, 1) 0%, rgba(255, 37, 37, 1) 100%);
    background: rgb(226, 133, 176);
    color: #fff;
    float: right;
  }
  &.primary3 {
    //background: linear-gradient(90deg, rgba(255, 229, 59, 1) 0%, rgba(255, 37, 37, 1) 100%);   
    background: rgb(59, 164, 211);
    color: #fff;
    float: right;
  }
  &.primary4 {
    //background: linear-gradient(90deg, rgba(255, 229, 59, 1) 0%, rgba(255, 37, 37, 1) 100%);
    background: rgb(245, 179, 74);
    color: #fff;
    float: right;
  }
  &.primary5 {
    //background: linear-gradient(90deg, rgba(255, 229, 59, 1) 0%, rgba(255, 37, 37, 1) 100%);    
    background: rgb(161, 132, 207);
    color: #fff;
    float: right;
  }
  &.primary6 {
    //background: linear-gradient(90deg, rgba(255, 229, 59, 1) 0%, rgba(255, 37, 37, 1) 100%);
    background: rgb(181, 204, 80);
    color: #fff;
    float: right;
  }
   &.primary7 {
    //background: linear-gradient(90deg, rgba(255, 229, 59, 1) 0%, rgba(255, 37, 37, 1) 100%);   
    background: rgb(124, 167, 223);
    color: #fff;
    float: right;
  }
  &.primary8 {
    //background: linear-gradient(90deg, rgba(149, 208, 248, 1) 0%, rgba(224, 122, 238, 1) 100%);
    background: rgb(243, 134, 139);
    color: #fff;
  }
  &.primary9 {
    //background: linear-gradient(90deg, rgba(149, 208, 248, 1) 0%, rgba(224, 122, 238, 1) 100%);
    background: rgb(100, 120, 212);
    color: #fff;
  }

  &:active {
    opacity: 0.8;
  }
  &.mb {
    margin-top: 54px;
  }
  &.mt {
    margin-top: 20px;
  }
  &.rr {
    margin-right: 20px;
  }
  .nbx {
    flex: 1;
    overflow: hidden;
    font-size: 26px;   
    color: rgb(93, 112, 199);
    text-align: left;
    flex:0 0 auto;
    &.t {
      width: 80%;
    }
    &.w {
      width: 20%;
    }
    &.h1 {
      margin-left: 30px;
    }
    &.h2 {
      margin-left: 10px;
      //text-align: right; 
      //margin-right: 30px;        
    }    
    &.h3 {
      margin-left: 100px; 
    }
    &.d {
      margin-top: 30px; 
    }
    .nbx_1 {
      overflow: hidden;
      font-size: 26px;   
      color: rgb(182, 180, 180); 
      &.fo {
        font-size: 30px;
        color: rgb(43, 30, 87);
        margin-bottom: 10px; 
      }
      &.co {
        color:rgb(219, 55, 14); 
      }  
      .bq {
        color: rgb(139, 138, 138);   
        width: 200px;
      }
    }
  }
}

</style>
